import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loading from './Loading';
import './common.css'
import { useHistory } from "react-router-dom";


function Messageing() {

  let history = useHistory();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
        return;
      }
      switch (event.key) {
        case 'Backspace':
          event.preventDefault();
          history.push('/')
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const [selectedWans, setSelectedWans] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);

  const column = {
    user_list: "username",
    customer_list: "name",
    vendor_list: "name",
    driver_list: "drivername",
  }

  const [selectedOptionOfDdl, setSelectedOptionOfDdl] = useState("user_list");

  const [people, setPeople] = useState([]);
  const [people1, setPeople1] = useState([]);

  const [messageId, setMessageId] = useState("");
  const [message, setMessage] = useState("");

  const [inputValues, setInputValues] = useState([""]);
  const scrollRef = useRef(null);

  const [showMessageList, setShowMessageList] = useState(true)
  const [showNewMessage, setShowNewMessage] = useState(false)

  const [showNewUser, setShowNewUser] = useState(true)
  const [showListsOfUsers, setShowListsOfUsers] = useState(false)

  const [mainCheckbox, setMainCheckbox] = useState(false);


  useEffect(() => {
    axios.get("https://rt-rl-api.aajkaa.in/whatsappverification/getmessages", {
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((response) => {
      setLoading1(false);
      setPeople1(response.data);
    });
  }, [])

  const leftSection = (e) => {
    let value = e.target.value
    console.log(value)
    if (value == 'messagelist') {
      setShowMessageList(true)
      setShowNewMessage(false)
      setMessage("")
      setMessageId("")
    } else {
      setShowNewMessage(true)
      setShowMessageList(false)
      setMessage("")
    }
  }


  const rightSection = (e) => {
    let value = e.target.value
    setSelectedOptionOfDdl(value)
    console.log(value)
    if (value == 'newwan') {
      setShowNewUser(true)
      setShowListsOfUsers(false)
    }
    if (value == 'user_list' || value == 'customer_list' || value == 'vendor_list' || value == 'driver_list') {
      setShowListsOfUsers(true)
      setShowNewUser(false)
      setLoading(true)
      axios.post("https://rt-rl-api.aajkaa.in/whatsappverification/gettabledata", { table: value }, {
        headers: { accessToken: localStorage.getItem("accessToken") },
      }).then((response) => {
        setLoading(false);
        setPeople(response.data);
        console.log(response.data)
      });
    }
  }


  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [inputValues]);


  const handleSubmit = (event) => {
    event.preventDefault();
    let optionsString = null
    optionsString = inputValues.filter(value => value).join(',')
    console.log(optionsString)
    if (optionsString == "") {
      optionsString = null
    }
    console.log(optionsString, message)
    if (optionsString == null) {
      alert("Enter Numbers")
      return
    } if (message == "") {
      alert("Enter or select message")
      return
    }
    alert("WhatsApp sending has started")

    axios.post("https://rt-rl-api.aajkaa.in/onlysendwan/sendwanmessages", { numberstring: optionsString, message: message }).then((response) => {
      alert(response.data)
      setMessage("")
      setInputValues([""])
      setMessageId("")
    });
  };

  const handleInputChange = (index, event) => {
    const values = [...inputValues];
    values[index] = event.target.value;
    setInputValues(values);
    console.log(values)

    if (index === inputValues.length - 1 && event.target.value !== '') {
      setInputValues([...values, '']);
    }
  };


  const handleCheckboxChange = (wan) => {
    setSelectedWans(prevSelectedWans => {
      if (prevSelectedWans.includes(wan)) {
        return prevSelectedWans.filter(selectedWan => selectedWan !== wan);
      } else {
        return [...prevSelectedWans, wan];
      }
    });
  };



  const handleMainCheckboxChange = () => {
    setMainCheckbox(!mainCheckbox);
    if (!mainCheckbox) {
      setSelectedWans(people.map(person => person.wan));
    } else {
      setSelectedWans([]);
    }
  };


  const sendWhatsappMessages = () => {
    console.log(selectedWans)
    if (selectedWans.length == 0 || message == "") {
      alert("Fill all the fields")
      return
    }
    alert("WhatsApp sending has started")
    axios.post("https://rt-rl-api.aajkaa.in/onlysendwan/sendwanmessagesformessagingtile", { selectedWans: selectedWans, message: message }).then((response) => {
      alert(response.data)
      setMessage("")
      setSelectedWans([])
      setMessageId("")
      setMainCheckbox(false)
    });
  }

  return (
    <div className="container">
      <h2 className='text-primary text-center my-3'>Send WhatsApp Message</h2>
      <div className="d-flex">
        <Link to='/' className="btn btn-primary"><i className="fa-solid fa-caret-left mx-2"></i>Back</Link>
        <select className='ms-2' onChange={(e) => { leftSection(e) }}>
          <option value="messagelist">Message List</option>
          <option value="newmessage">New Message</option>
        </select>
        <select className='ms-2' onChange={(e) => { rightSection(e) }}>
          <option value="newwan">New WAN</option>
          <option value="" disabled>Case ID</option>
          <option value="user_list">User List</option>
          <option value="customer_list">Customer List</option>
          <option value="vendor_list">Vendor List</option>
          <option value="driver_list">Driver List</option>
        </select>
      </div>
      <div className='mt-3'>
        <div className='row'>
          {showMessageList &&
            <div className="col-md-4 mt-4">
              <div className='overflow-auto' style={{ maxHeight: "60vh", padding: "20px", border: "1px solid black" }}>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Select a message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading1 ? (
                      <tr>
                        <td>
                          <Loading />
                        </td>
                      </tr>
                    ) : (
                      people1.map((person, index) => (
                        <tr key={index}>
                          <td>
                            <textarea
                              onClick={() => { setMessageId(person.id); setMessage(person.message); }}
                              style={messageId === person.id ? { background: "#00c600", color: "white", border: "1px solid #00c600", padding: "5px 10px" } : {}}
                              readOnly
                              rows={5}
                              className="w-100"
                            >
                              {person.message}
                            </textarea>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          }
          {showNewMessage &&
            <div className="col-md-4 mt-4">
              <textarea rows="10" className='col-12' value={message} onChange={(e) => { setMessage(e.target.value); setMessageId("") }} placeholder='Enter new message'></textarea>
            </div>
          }
          <div className="col-md-3 mt-4">
            {showNewUser &&
              inputValues.length > 0 && (
                <div ref={scrollRef} className='d-flex flex-column' style={{ maxHeight: "40vh", overflowY: "scroll" }}>
                  {inputValues.map((value, index) => (
                    <input
                      className='mt-1'
                      key={index}
                      type="number"
                      value={value}
                      onChange={(e) => handleInputChange(index, e)}
                      placeholder='Enter WAN'
                    />
                  ))}
                  <div className='d-flex justify-content-center mt-2'>
                    <button className='btn btn-primary btn-sm' style={{ width: "60%" }} onClick={handleSubmit} >Send Message</button>
                  </div>
                </div>
              )
            }
            {showListsOfUsers &&
              <>
                <div className='overflow-auto' style={{ maxHeight: "45vh", border: "1px solid black" }}>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th><input type="checkbox" onChange={handleMainCheckboxChange} checked={mainCheckbox} /></th>
                        <th>Name</th>
                        <th>WAN</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td>
                            <Loading />
                          </td>
                        </tr>
                      ) : (
                        people.map((person, index) => (
                          <tr key={index}>
                            <td><input type="checkbox" onChange={() => handleCheckboxChange(person.wan)} checked={selectedWans.includes(person.wan)} /></td>
                            <td>{person[column[selectedOptionOfDdl]]}</td>
                            <td>{person.wan}{person.wanverified == true ? <i title="Verified" className="text-success ms-2 fa-solid fa-square-check"></i> : <i title='Unverified' className="text-warning ms-2 fa-solid fa-triangle-exclamation"></i>}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <button className='btn btn-primary mt-2 mx-auto d-flex' onClick={() => { sendWhatsappMessages() }}>Send Message</button>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Messageing;
