import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from './Loading';
import axios from 'axios'
import { useHistory } from "react-router-dom";



function Wanverification() {
  let history = useHistory();


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
        return;
      }
      switch (event.key) {
        case 'Backspace':
          event.preventDefault();
          history.push('/')
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const [selectedOptionOfDdl, setSelectedOptionOfDdl] = useState("user_list")
  const [whatsappNumber, setWhatsappNumber] = useState("")
  const [generatedOtp, setGeneratedOtp] = useState("")
  const [otp, setOtp] = useState("")
  const [id, setId] = useState("")

  const [showListsOfUsers, setShowListsOfUsers] = useState(false)
  const [loading, setLoading] = useState(true);
  const [people, setPeople] = useState([]);
  const column = {
    user_list: "username",
    customer_list: "name",
    vendor_list: "name",
    driver_list: "drivername",
  }

  useEffect(() => {
    let d = new Date()
    let cid = d.getTime()
    let cid2 = cid.toString()
    setGeneratedOtp(cid2.slice(-4))
  }, [generatedOtp])


  useEffect(() => {
    axios.post("https://rt-rl-api.aajkaa.in/whatsappverification/gettabledataofunverified", { table: selectedOptionOfDdl }, {
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((response) => {
      setLoading(false);
      setPeople(response.data);
      setShowListsOfUsers(true)
    });
  }, [])


  const checkwhatsappnumber = () => {
    console.log('1', generatedOtp)
    axios.post("https://rt-rl-api.aajkaa.in/whatsappverification/checkwhatsappno", { whatsappNumber: whatsappNumber, generatedOtp: generatedOtp, selectedOptionOfDdl: selectedOptionOfDdl }, {
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((response) => {
      console.log(response.data[1])
      if (response.data[1].length != 0) {
        setId(response.data[1][0][0].id)
      }
      if (response.data) {
        alert(response.data[0])
      }
    });
  }

  const verifyotp = () => {
    console.log(otp, generatedOtp)
    if (otp == generatedOtp) {
      axios.post("https://rt-rl-api.aajkaa.in/whatsappverification/updateverificationstatus", { id: id, selectedOptionOfDdl: selectedOptionOfDdl }, {
        headers: { accessToken: localStorage.getItem("accessToken") },
      }).then((response) => {
        alert(response.data)
        setSelectedOptionOfDdl('user_list')
        setWhatsappNumber("")
        setGeneratedOtp("")
        setOtp("")
        setId("")
      })
    } else {
      alert('not verified')
    }
  }


  const leftSection = (e) => {
    let value = e.target.value
    setSelectedOptionOfDdl(value)
    console.log(value)
    setShowListsOfUsers(true)
    setLoading(true)
    axios.post("https://rt-rl-api.aajkaa.in/whatsappverification/gettabledataofunverified", { table: value }, {
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((response) => {
      setLoading(false);
      setPeople(response.data);
    });
  }

  return (
    <div>
      <div className="container">
        <h2 className='text-primary text-center my-3'>Verify WhatsApp Number</h2>
        <Link to='/' className="btn btn-primary"><i className="fa-solid fa-caret-left mx-2"></i>Back</Link>
        <div className="container mt-4 d-flex">
          <div className="">
            <select autoFocus value={selectedOptionOfDdl} onChange={(e) => { leftSection(e) }} defaultValue="">
              <option value="user_list" selected>User List</option>
              <option value="customer_list">Customer List</option>
              <option value="vendor_list">Vendor List</option>
              <option value="driver_list">Driver List</option>
            </select>
            {showListsOfUsers &&
              <>
                <div className='overflow-auto' style={{ maxHeight: "45vh", border: "1px solid black" }}>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>WAN</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td>
                            <Loading />
                          </td>
                        </tr>
                      ) : (
                        people.map((person, index) => (
                          <tr key={index} onClick={() => { setWhatsappNumber(person.wan) }}>
                            <td>{person[column[selectedOptionOfDdl]]}</td>
                            <td>{person.wan}{person.wanverified == true ? <i title="Verified" className="text-success ms-2 fa-solid fa-square-check"></i> : <i title='Unverified' className="text-warning ms-2 fa-solid fa-triangle-exclamation"></i>}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                {/* <button className='btn btn-primary mt-2 mx-auto d-flex' onClick={() => { sendWhatsappMessages() }}>Send Message</button> */}
              </>
            }
          </div>
          <div className='ms-3'>
            <div className='d-flex'>
              <input type="number" value={whatsappNumber} placeholder='Whatsapp Number' onChange={(e) => { setWhatsappNumber(e.target.value) }} />
              <button className='ms-3 btn btn-primary btn-sm' onClick={() => { checkwhatsappnumber() }}>send OTP</button>
            </div>
            <div className='mt-2 d-flex'>
              <input type="number" value={otp} placeholder='OTP' onChange={(e) => { setOtp(e.target.value) }} />
              <button className='ms-3 btn btn-primary btn-sm' onClick={() => { verifyotp() }}>Verify OTP</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wanverification
