import React, { useRef, useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { AuthContext } from "../helpers/AuthContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddInMaster = ({ data }) => {
    const { authState } = useContext(AuthContext);

    const modalRef = useRef(null);


    const [entityIdList, setEntityIdList] = useState([]);

    useEffect(() => {
        axios.post("https://rt-rl-api.aajkaa.in/ddlist/getcolumndata", { columnName: "id_doc" }, {
            headers: { accessToken: localStorage.getItem("accessToken") },
        }).then((response) => {
            if (response.data[0]['id_doc'] == null) {
                setEntityIdList([])
                return
            }
            setEntityIdList(response.data[0]['id_doc'].split(','))
        });
    }, []);


    const [isModalOpen, setIsModalOpen] = useState(true)

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleCancel();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleCancel]);


    const [editData, setEditData] = useState(data.formdata);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData((prev) => ({
            ...prev,
            [name]: value
        }));
    };


    const handleSave = () => {
        const person = {
            ...editData,
            wanverified: false,
            createdby: authState.username,
            performance: '',
        };
        axios.post('https://rt-rl-api.aajkaa.in/customerlist/', person, {
            headers: { accessToken: localStorage.getItem("accessToken") },
        }).then((res) => {
            toast.success('Added successfully...', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsModalOpen(false);
        });
    };


    const formDetails = {
        customerlist:
            <>
                <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Add New Customer</h3>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px', marginBottom: '20px' }}>
                    <div>
                        <label>Customer Name</label>
                        <input type="text" name="name" value={editData.name} onChange={handleInputChange} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} autoFocus/>
                    </div>

                    <div>
                        <label>Address</label>
                        <input type="text" onChange={handleInputChange} name="address" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                    </div>

                    <div>
                        <label>Contact Details</label>
                        <input type="text" onChange={handleInputChange} name="contactdetails" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                    </div>

                    <div>
                        <label>Whatsapp Number</label>
                        <input type="text" onChange={handleInputChange} name="wan" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                    </div>

                    <div>
                        <label>ID Document</label>
                        <select onChange={handleInputChange} name="id_doc" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}>
                            <option value="" disabled selected>ID Document</option>
                            {entityIdList.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label>ID Document No</label>
                        <input type="text" onChange={handleInputChange} name="id_doc_no" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                    </div>

                    <div>
                        <label>ID Document Validity</label>
                        <input type="date" onChange={handleInputChange} name="id_doc_validity" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                    </div>

                    <div>
                        <label>Reference</label>
                        <input type="text" onChange={handleInputChange} name="reference" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                    </div>
                </div>

            </>
    }

    return (
        <div>
            <ToastContainer />
            {isModalOpen && (
                <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: '65px', zIndex: 1000 }}>
                    <div ref={modalRef} style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '90%', maxWidth: '1000px', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', display: 'flex', flexDirection: 'column', }} >
                        {formDetails[data.form]}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', padding: '10px 0', borderTop: '1px solid #ddd', backgroundColor: 'white' }}>
                            <button onClick={handleCancel} style={{ padding: '10px 20px', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }} >Cancel</button>
                            <button onClick={handleSave} style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }} >Save</button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default AddInMaster
