import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import '../common.css';
import LoadingBar from 'react-top-loading-bar'
import Loading from '../Loading'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../helpers/AuthContext";
import { useHistory } from "react-router-dom";


const UserType = () => {
  let history = useHistory();

  const { authState } = useContext(AuthContext);

  const [people, setPeople] = useState([]);

  const [usertype, setUserType] = useState('');
  const [newenquiry, setNewEnquiry] = useState(false);
  const [existingenquiry, setExistingEnquiry] = useState(false);
  const [vehicalstatus, setVehicalStatus] = useState(false);
  const [masterlist, setMasterList] = useState(false);
  const [wanverification, setWanVerification] = useState(false);
  const [financialaccounting, setFinancialAccounting] = useState(false);
  const [messaging, setMessaging] = useState(false);
  const [reports, setReports] = useState(false);
  const [adminmodule, setAdminModule] = useState(false);
  const [assetmanagement, setAssetManagement] = useState(false);
  const [closedcase, setClosedCase] = useState(false);

  const [disableUpdate, setDisableUpdate] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);

  const [editIndex, setEditIndex] = useState(null);

  const [showAdd, setShowAdd] = useState(false);

  const [showFindSearch, setShowFindSearch] = useState(false);

  const [columnOrder, setColumnOrder] = useState([['usertype', 'text', '100px'], ['newenquiry', 'checkbox', '100px'], ['vehicalstatus', 'checkbox', '100px'], ['masterlist', 'checkbox', '100px'], ['wanverification', 'checkbox', '100px'], ['financialaccounting', 'checkbox', '100px'], ['messaging', 'checkbox', '100px'], ['reports', 'checkbox', '100px'], ['adminmodule', 'checkbox', '100px'], ['assetmanagement', 'checkbox', '100px'], ['closedcase', 'checkbox', '100px']]);

  const [loading, setLoading] = useState(true)


  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log(event.target.tagName)
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
        return;
      }
      switch (event.key) {
        case 'F2':
          event.preventDefault();
          showOneHideAll();
          setShowAdd(showAdd ? false : true);
          setDisableUpdate(showAdd ? false : true);
          break;
        case 'F3':
          event.preventDefault();
          showOneHideAll();
          setShowFindSearch(showFindSearch ? false : true)
          break;
        case 'F5':
          event.preventDefault();
          fetchPeople()
          break;
        // case 'Backspace':
        //     event.preventDefault();
        //     history.push('/')
        //     break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    fetchPeople();

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl));

    // Cleanup function to destroy tooltips when the component unmounts
    return () => {
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        const tooltip = window.bootstrap.Tooltip.getInstance(tooltipTriggerEl);
        if (tooltip) {
          tooltip.dispose();
        }
      });
    };
  }, []);

  const fetchPeople = async () => {
    try {
      setProgress(10)
      const response = await axios.get('https://rt-rl-api.aajkaa.in/Usertypes/', {
        headers: { accessToken: localStorage.getItem("accessToken") }
      });
      setPeople(response.data);
      console.log('data', response.data)
      setLoading(false)
      setProgress(100)
    } catch (error) {
      console.error('Error fetching people', error);
    }
  };

  const handleAddPerson = async (e) => {
    e.preventDefault()
    const person = { usertype, createdby: authState.username, newenquiry, existingenquiry, vehicalstatus, masterlist, wanverification, financialaccounting, messaging, reports, adminmodule, assetmanagement, closedcase };
    if (editIndex !== null) {
      try {
        const person = { usertype, updatedby: authState.username, newenquiry, existingenquiry, vehicalstatus, masterlist, wanverification, financialaccounting, messaging, reports, adminmodule, assetmanagement, closedcase };
        await axios.put(`https://rt-rl-api.aajkaa.in/Usertypes/${people[editIndex].id}`, person, {
          headers: { accessToken: localStorage.getItem("accessToken") },
        });
        setDisableAdd(false)
      } catch (error) {
        console.error('Error updating person', error);
      }
    } else {
      try {
        await axios.post('https://rt-rl-api.aajkaa.in/Usertypes/', person, {
          headers: { accessToken: localStorage.getItem("accessToken") },
        });
        setShowAdd(false)
        setDisableUpdate(false)
        toast.success('Added successfully...', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (error) {
        console.error('Error adding person', error);
      }
    }
    resetFormAndReloadPeople();
  };

  const resetFormAndReloadPeople = async () => {
    setUserType("")
    setNewEnquiry(false)
    setExistingEnquiry(false)
    setVehicalStatus(false)
    setMasterList(false)
    setWanVerification(false)
    setFinancialAccounting(false)
    setMessaging(false)
    setReports(false)
    setAdminModule(false)
    setAssetManagement(false)
    setClosedCase(false)

    setShowAdd(false);
    setShowFindSearch(false);
    setEditIndex(null);

    await fetchPeople();
  };

  const handleEditPerson = (index) => {
    const personToEdit = people[index];

    setEditIndex(index);

    setUserType(personToEdit.usertype)
    setNewEnquiry(personToEdit.newenquiry)
    setExistingEnquiry(personToEdit.existingenquiry)
    setVehicalStatus(personToEdit.vehicalstatus)
    setMasterList(personToEdit.masterlist)
    setWanVerification(personToEdit.wanverification)
    setFinancialAccounting(personToEdit.financialaccounting)
    setMessaging(personToEdit.messaging)
    setReports(personToEdit.reports)
    setAdminModule(personToEdit.adminmodule)
    setAssetManagement(personToEdit.assetmanagement)
    setClosedCase(personToEdit.closedcase)
  };

  const handleCancelBtn = () => {
    setDisableAdd(false)
    setEditIndex(null);

    setUserType("")
    setNewEnquiry(false)
    setExistingEnquiry(false)
    setVehicalStatus(false)
    setMasterList(false)
    setWanVerification(false)
    setFinancialAccounting(false)
    setMessaging(false)
    setReports(false)
    setAdminModule(false)
    setAssetManagement(false)
    setClosedCase(false)
  };

  const handleDeletePerson = async (index) => {
    try {
      await axios.delete(`https://rt-rl-api.aajkaa.in/Usertypes/${people[index].id}`, {
        headers: { accessToken: localStorage.getItem("accessToken") }
      });
      fetchPeople();
    } catch (error) {
      console.error('Error deleting person', error);
    }
  };

  const handleDragStart = (e, position) => {
    e.dataTransfer.setData('text/plain', position);
  };

  const handleDrop = (e, targetPosition) => {
    e.preventDefault();
    const sourcePosition = e.dataTransfer.getData('text/plain');
    const newOrder = [...columnOrder];
    const [removedItem] = newOrder.splice(sourcePosition, 1);
    newOrder.splice(targetPosition, 0, removedItem);

    setColumnOrder(newOrder);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const columnType = {
    'userType': 'string',
    'newEnquiry': 'checkbox',
    'existingEnquiry': 'checkbox',
    'vehicalStatus': 'checkbox',
    'masterList': 'checkbox',
    'wanVerification': 'checkbox',
    'financialAccounting': 'checkbox',
    'messaging': 'checkbox',
    'reports': 'checkbox',
    'adminModule': 'checkbox',
    'assetManagement': 'checkbox',
    'closedCase': 'checkbox'
  }

  const [searchColumn, setSearchColumn] = useState("usertype")
  const [searchSign, setSearchSign] = useState("contains")
  const [searchValue, setSearchValue] = useState("")
  const [signFromColType, setSignFromColType] = useState(false)


  const setSearchColType = (newValue) => {
    setSearchColumn(newValue);
    if (columnType[newValue] === "number") {
      setSignFromColType(true);
      setSearchSign("=")
    }

    if (columnType[newValue] === "string") {
      setSignFromColType(false);
      setSearchSign("contains")
    }
  };


  const filterSearch = (e) => {
    e.preventDefault();
    console.log(searchColumn, searchSign, searchValue)
    axios.post('https://rt-rl-api.aajkaa.in/Usertypes/search', {
      col: searchColumn,
      sign: searchSign,
      value: searchValue
    }, {
      headers: { accessToken: localStorage.getItem("accessToken") }
    }).then((response) => {
      setPeople(response.data)
    })
  }

  const sortPeople = (column, order) => {
    console.log(column, order)
    axios.post('https://rt-rl-api.aajkaa.in/Usertypes/sort', {
      col: column,
      order: order,
    }, {
      headers: { accessToken: localStorage.getItem("accessToken") }
    }).then((response) => {
      setPeople(response.data)
    })
  };

  const [sortColName, setSortColName] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const handleSort = (e, column) => {
    e.preventDefault();

    setSortColName(prevSortColName => {
      if (column !== prevSortColName || sortOrder === "") {
        setSortOrder("ASC");
      } else if (column === prevSortColName && sortOrder === "ASC") {
        setSortOrder("DESC");
      } else if (sortOrder === "DESC") {
        setSortOrder("ASC");
      }
      return column;
    });

  };

  useEffect(() => {
    if (sortColName != "" && sortOrder != "") {
      sortPeople(sortColName, sortOrder)
    }
    console.log(sortColName, sortOrder);
  }, [sortColName, sortOrder]);


  const [progress, setProgress] = useState(0)

  const setters = {
    usertype: setUserType,
    newenquiry: setNewEnquiry,
    existingenquiry: setExistingEnquiry,
    vehicalstatus: setVehicalStatus,
    masterlist: setMasterList,
    wanverification: setWanVerification,
    financialaccounting: setFinancialAccounting,
    messaging: setMessaging,
    reports: setReports,
    adminmodule: setAdminModule,
    assetmanagement: setAssetManagement,
    closedcase: setClosedCase,
  };

  const settersvariable = {
    usertype: usertype,
    newenquiry: newenquiry,
    existingenquiry: existingenquiry,
    vehicalstatus: vehicalstatus,
    masterlist: masterlist,
    wanverification: wanverification,
    financialaccounting: financialaccounting,
    messaging: messaging,
    reports: reports,
    adminmodule: adminmodule,
    assetmanagement: assetmanagement,
    closedcase: closedcase,
  };


  const setterscolumns = {
    usertype: <>User <br /> Type</>,
    newenquiry: <>New <br /> Enquiry</>,
    existingenquiry: <>Existing <br /> Enquiry</>,
    vehicalstatus: <>Vehical <br /> Status</>,
    masterlist: <>Master <br /> List</>,
    wanverification: <>WAN <br /> Verification</>,
    financialaccounting: <>Financial <br /> Accounting</>,
    messaging: <>Messaging</>,
    reports: <>Reports</>,
    adminmodule: <>Admin <br /> Module</>,
    assetmanagement: <>Asset <br /> Management</>,
    closedcase: <>Closed <br /> Case</>,
  };

  const setterscolumnstootip = {
    usertype: "User Type",
    newenquiry: "New Enquiry",
    existingenquiry: "Existing Enquiry",
    vehicalstatus: "Vehical Status",
    masterlist: "Master List",
    wanverification: "WAN Verification",
    financialaccounting: "Financial Accounting",
    messaging: "Messaging",
    reports: "Reports",
    adminmodule: "Admin Module",
    assetmanagement: "Asset Management",
    closedcase: "Closed Case",
  };

  const showOneHideAll = () => {
    setShowAdd(false)
    setShowFindSearch(false)
    setDisableUpdate(false)
  }

  return (
    <div className=''>
      <ToastContainer />
      <LoadingBar height={5} color='#f11946' progress={progress} />
      <button className='btn btn-primary me-2' disabled={disableAdd} onClick={() => { showOneHideAll(); setShowAdd(showAdd ? false : true); setDisableUpdate(showAdd ? false : true); }}><i className="fa-solid fa-plus me-2"></i>Add New UserType (F2)</button>
      <button className='btn btn-success me-2' onClick={() => { showOneHideAll(); setShowFindSearch(showFindSearch ? false : true) }}> <i className="fa-solid fa-magnifying-glass me-2"></i>Find / Search (F3)</button>
      <button className='btn btn-warning me-2' onClick={() => fetchPeople()}><i className="fa-solid fa-eraser me-2"></i>Clear all find and sort (F5)</button>
      {
        showAdd && (
          <form className='mt-2' style={{ border: '1px solid black', padding: "10px", width: "fit-content" }} onSubmit={(e) => { handleAddPerson(e) }}>
            <input
              type="text"
              value={usertype}
              onChange={(e) => setUserType(e.target.value)}
              placeholder="User Type"
              autoFocus
              required
            />
            <button type='submit' className='btn btn-sm btn-primary ms-2' >{editIndex !== null ? 'Update' : 'Add'}</button>
          </form>
        )
      }

      {
        showFindSearch && (
          <form onSubmit={(e) => filterSearch(e)} className='mt-2' style={{ border: '1px solid black', padding: "10px", width: "fit-content" }}>
            <select onChange={(e) => { setSearchColType(e.target.value); }} className='me-2' style={{ height: "30px", width: "150px" }}>
              {columnOrder.map((column, index) => (
                column[1] == 'checkbox' ? <></> :
                  <option value={column[0]}>{column[0]}</option>
              ))}
            </select>
            <select onChange={(e) => { setSearchSign(e.target.value) }} required className='me-2' style={{ height: "30px", width: "150px" }}>
              {signFromColType == true ?
                <>
                  <option value="="> = (Equal)</option>
                  <option value="!="> != (Not Equal)</option>
                  <option value="<="> {'<='} (Less Than or Equal)</option>
                  <option value=">=">{'>='} (Greater Than or Equal)</option>
                  <option value="<"> {'<'} (Less Than)</option>
                  <option value=">"> {'>'} (Greater Than)</option>
                </>
                :
                <>
                  <option value="contains">Contains</option>
                  <option value="doesnotcontains">Does not contains</option>
                  <option value="beginwith">Begin with</option>
                </>
              }
            </select>

            <input type="text" onChange={(e) => { setSearchValue(e.target.value) }} required className='me-2' style={{ height: "30px", width: "150px" }} />
            <button type='submit' className='btn btn-success'>Search</button>
          </form>
        )
      }
      <div className='mt-2' style={{ width: '100%', maxHeight: "70vh", overflow: 'auto', padding: "5px 150px 10px 10px", border: "1px solid black" }}>
        <table className='' style={{ width: '100%', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              {columnOrder.map((column, index) => (
                <th
                  key={column}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDrop={(e) => handleDrop(e, index)}
                  onDragOver={handleDragOver}
                  onClick={(e) => { handleSort(e, column[0]) }}
                  data-bs-toggle="tooltip" data-bs-placement="top" title={setterscolumnstootip[column[0]]}
                  style={{ padding: "0 10px", width: column[2], textAlign: "center", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  <i className="fa-solid fa-sort me-2"></i>{setterscolumns[column[0]]}
                </th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={columnOrder.length + 1}>
                  <Loading />
                </td>
              </tr>
            ) : (
              people.map((person, index) => (
                <tr key={person.id}>
                  {columnOrder.map((column) => (
                    <td key={`${person.id}-${column}`} style={{ textAlign: "center", "border": "1px solid black", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {editIndex === index ? (
                        column[1] === 'checkbox' ? (
                          <input
                            type="checkbox"
                            checked={settersvariable[column[0]]}
                            onChange={(e) => setters[column[0]](e.target.checked)}
                          />
                        ) : (
                          <input
                            type={column[1]}
                            value={settersvariable[column[0]]}
                            onChange={(e) => setters[column[0]](e.target.value)}
                            style={{ width: '100%' }}
                          />
                        )
                      ) : (
                        column[1] === "checkbox" ? (
                          <input
                            align='center'
                            type="checkbox"
                            checked={person[column[0]]}
                          />
                        ) : (
                          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={String(person[column[0]])}>{String(person[column[0]])}</span>
                        )
                      )}
                    </td>
                  ))}
                  <td>
                    {editIndex === index ? (
                      <div style={{ display: "flex" }}>
                        <button className='btn btn-sm btn-secondary ms-2' onClick={handleCancelBtn}>Cancel</button>
                        <button className='btn btn-sm btn-success ms-2' onClick={handleAddPerson}>Save</button>
                      </div>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <button className='btn btn-sm btn-warning ms-2' disabled={disableUpdate} onClick={() => { setDisableAdd(true); handleEditPerson(index) }}>Edit</button>
                        <button className='btn btn-sm btn-danger ms-2' onClick={() => handleDeletePerson(index)} style={{ marginRight: "50px" }}>Delete</button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div >
  )
}

export default UserType