import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import '../common.css';
import LoadingBar from 'react-top-loading-bar'
import Loading from '../Loading'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../helpers/AuthContext";


const ThirdPartyAccess = () => {

    const input1 = useRef(null)
    const input2 = useRef(null)

    const { authState } = useContext(AuthContext);

    const [people, setPeople] = useState([]);

    const [allVendorsName, setAllVendorsName] = useState([]);
    const [allDriversName, setAllDriversName] = useState([]);
    const [allVehicleNo, setAllVehicleNo] = useState([]);

    const [vehicle_no, setVehicleNo] = useState('');
    const [username, setUserName] = useState('');
    const [wan, setWan] = useState('');
    const [usertype, setUserType] = useState('');
    const [disable, setDisable] = useState('no');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [disableUpdate, setDisableUpdate] = useState(false);
    const [disableAdd, setDisableAdd] = useState(false);

    const [editIndex, setEditIndex] = useState(null);

    const [showAdd, setShowAdd] = useState(false);

    const [showFindSearch, setShowFindSearch] = useState(false);

    const [columnOrder, setColumnOrder] = useState([['vehicle_no', 'select'], ['username', 'select'], ['wan', 'text'], ['usertype', 'select'], ['disable', 'select']]);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
                return;
            }
            switch (event.key) {
                case 'F2':
                    event.preventDefault();
                    showOneHideAll();
                    setShowAdd(showAdd ? false : true);
                    setDisableUpdate(showAdd ? false : true);
                    break;
                case 'F3':
                    event.preventDefault();
                    showOneHideAll();
                    setShowFindSearch(showFindSearch ? false : true)
                    break;
                case 'F5':
                    event.preventDefault();
                    fetchPeople()
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        fetchPeople();

        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map((tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl));

        // Cleanup function to destroy tooltips when the component unmounts
        return () => {
            tooltipTriggerList.forEach((tooltipTriggerEl) => {
                const tooltip = window.bootstrap.Tooltip.getInstance(tooltipTriggerEl);
                if (tooltip) {
                    tooltip.dispose();
                }
            });
        };
    }, []);

    const fetchPeople = async () => {
        try {
            setProgress(10)
            const response = await axios.get('https://rt-rl-api.aajkaa.in/thirdpartyaccess/read', {
                headers: { accessToken: localStorage.getItem("accessToken") }
            });

            const allvendorsname = await axios.get('https://rt-rl-api.aajkaa.in/vendorlist/getvendornamewan/', {
                headers: { accessToken: localStorage.getItem("accessToken") }
            });

            const alldriversname = await axios.get('https://rt-rl-api.aajkaa.in/driverlist/getdrivernamewan/', {
                headers: { accessToken: localStorage.getItem("accessToken") }
            });

            const allvehicleno = await axios.get('https://rt-rl-api.aajkaa.in/vehiclelist/getvehiclenoonly/', {
                headers: { accessToken: localStorage.getItem("accessToken") }
            });

            setPeople(response.data);
            setAllVendorsName(allvendorsname.data);
            setAllDriversName(alldriversname.data);
            setAllVehicleNo(allvehicleno.data);
            setLoading(false)
            setProgress(100)
        } catch (error) {
            console.error('Error fetching people', error);
        }
    };

    const handleAddPerson = async (e) => {
        e.preventDefault()
        let person = { vehicle_no, username, wan, usertype, disable, password, wanverified: false, createdby: authState.username };

        if (editIndex !== null) {
            try {
                person = { vehicle_no, username, wan, usertype, disable, updatedby: authState.username };
                await axios.put(`https://rt-rl-api.aajkaa.in/thirdpartyaccess/${people[editIndex].id}`, person, {
                    headers: { accessToken: localStorage.getItem("accessToken") },
                });
                setDisableAdd(false)
                resetFormAndReloadPeople();
            } catch (error) {
                console.error('Error updating person', error);
            }
        } else {
            try {
                if (password != confirmPassword) {
                    toast.error('Password and confirm password should be same.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    return
                }
                await axios.post('https://rt-rl-api.aajkaa.in/thirdpartyaccess/', person, {
                    headers: { accessToken: localStorage.getItem("accessToken") },
                }).then((res) => {
                    if (res.data == 'User Name Is Already Exists') {
                        toast.error('User name is already exists.', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }
                    if (res.data == 'SUCCESS') {
                        toast.success('Added successfully...', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        resetFormAndReloadPeople();
                        setShowAdd(false)
                        setDisableUpdate(false)
                    }
                });
            } catch (error) {
                console.error('Error adding person', error);
            }
        }
    };

    const resetFormAndReloadPeople = async () => {
        setUserName("")
        setWan("")
        setUserType("")
        setDisable("no")
        setPassword("")
        setConfirmPassword("")
        setVehicleNo("")

        setShowAdd(false);
        setShowFindSearch(false);
        setEditIndex(null);

        await fetchPeople();
    };

    const handleEditPerson = (index) => {
        const personToEdit = people[index];

        setEditIndex(index);

        setUserName(personToEdit.username)
        setWan(personToEdit.wan)
        setUserType(personToEdit.usertype)
        setDisable(personToEdit.disable)
        setPassword(personToEdit.password)
        setVehicleNo(personToEdit.vehicle_no)
    };

    const handleCancelBtn = () => {
        setDisableAdd(false)
        setEditIndex(null);

        setUserType("")
        setWan("")
        setUserName("")
        setDisable("no")
        setPassword("")
        setVehicleNo("")
    };

    const handleDeletePerson = async (index) => {
        try {
            await axios.delete(`https://rt-rl-api.aajkaa.in/thirdpartyaccess/${people[index].id}`, {
                headers: { accessToken: localStorage.getItem("accessToken") },
            });
            fetchPeople();
        } catch (error) {
            console.error('Error deleting person', error);
        }
    };

    const handleDragStart = (e, position) => {
        e.dataTransfer.setData('text/plain', position);
    };

    const handleDrop = (e, targetPosition) => {
        e.preventDefault();
        const sourcePosition = e.dataTransfer.getData('text/plain');
        const newOrder = [...columnOrder];
        const [removedItem] = newOrder.splice(sourcePosition, 1);
        newOrder.splice(targetPosition, 0, removedItem);

        setColumnOrder(newOrder);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const columnType = {
        'usertype': 'select',
        'wan': 'string',
        'username': 'string',
        'disable': 'select',
        'password': 'string',
        'vehicle_no': 'string',
    }

    const [searchColumn, setSearchColumn] = useState("vehicle_no")
    const [searchSign, setSearchSign] = useState("contains")
    const [searchValue, setSearchValue] = useState("")
    const [signFromColType, setSignFromColType] = useState(false)


    const setSearchColType = (newValue) => {
        setSearchColumn(newValue);
        if (columnType[newValue] === "number") {
            setSignFromColType(true);
            setSearchSign("=")
        }

        if (columnType[newValue] === "string") {
            setSignFromColType(false);
            setSearchSign("contains")
        }
    };


    const filterSearch = (e) => {
        e.preventDefault();
        axios.post('https://rt-rl-api.aajkaa.in/thirdpartyaccess/search', {
            col: searchColumn,
            sign: searchSign,
            value: searchValue
        }, {
            headers: { accessToken: localStorage.getItem("accessToken") },
        }).then((response) => {
            setPeople(response.data)
        })
    }

    const sortPeople = (column, order) => {
        axios.post('https://rt-rl-api.aajkaa.in/thirdpartyaccess/sort', {
            col: column,
            order: order,
        }, {
            headers: { accessToken: localStorage.getItem("accessToken") },
        }).then((response) => {
            setPeople(response.data)
        })
    };

    const [sortColName, setSortColName] = useState("");
    const [sortOrder, setSortOrder] = useState("");

    const handleSort = (e, column) => {
        e.preventDefault();

        setSortColName(prevSortColName => {
            if (column !== prevSortColName || sortOrder === "") {
                setSortOrder("ASC");
            } else if (column === prevSortColName && sortOrder === "ASC") {
                setSortOrder("DESC");
            } else if (sortOrder === "DESC") {
                setSortOrder("ASC");
            }
            return column;
        });

    };

    useEffect(() => {
        if (sortColName != "" && sortOrder != "") {
            sortPeople(sortColName, sortOrder)
        }
    }, [sortColName, sortOrder]);


    const [progress, setProgress] = useState(0)

    const setters = {
        usertype: setUserType,
        wan: setWan,
        username: setUserName,
        disable: setDisable,
        password: setPassword,
        vehicle_no: setVehicleNo,
    };

    const settersvariable = {
        usertype: usertype,
        wan: wan,
        username: username,
        disable: disable,
        password: password,
        vehicle_no: vehicle_no,
    };


    const setterscolumns = {
        usertype: "User Type",
        wan: "WAN",
        username: "User Name",
        disable: "Disable",
        password: "Password",
        vehicle_no: "Vehicle No",
    };


    const settersselections = {
        usertype:
            <select style={{ width: "100%", height: "29.62px" }} value={usertype} onChange={(e) => setUserType(e.target.value)} required>
                <option value="" selected disabled>User Type</option>
                <option value="vendor">Vendor</option>
                <option value="driver">Driver</option>
            </select>,
        disable:
            <select style={{ width: "100%", height: "29.62px" }} value={disable} onChange={(e) => setDisable(e.target.value)} required>
                <option value="" disabled>Disable</option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>
    };


    const showOneHideAll = () => {
        setShowAdd(false)
        setShowFindSearch(false)
        setDisableUpdate(false)
    }

    const setUsertypeWan = (username) => {
        let vendorlists = allVendorsName.filter((val) => val.name.toLowerCase() == username.toLowerCase())
        if (vendorlists.length != 0) {
            setUserType('vendor')
            setWan(vendorlists[0].wan)
            input1.current.disabled = true
            input2.current.disabled = true
        }

        let driverlists = allDriversName.filter((val) => val.drivername.toLowerCase() == username.toLowerCase())
        if (driverlists.length != 0) {
            setUserType('driver')
            setWan(driverlists[0].wan)
            input1.current.disabled = true
            input2.current.disabled = true
        }
    }


    return (
        <div className=''>
            <ToastContainer />
            <LoadingBar height={5} color='#f11946' progress={progress} />
            <button className='btn btn-primary me-2' disabled={disableAdd} onClick={() => { showOneHideAll(); setShowAdd(showAdd ? false : true); setDisableUpdate(showAdd ? false : true); }}><i className="fa-solid fa-plus me-2"></i>Add New User (F2)</button>
            <button className='btn btn-success me-2' onClick={() => { showOneHideAll(); setShowFindSearch(showFindSearch ? false : true) }}> <i className="fa-solid fa-magnifying-glass me-2"></i>Find / Search (F3)</button>
            <button className='btn btn-warning me-2' onClick={() => fetchPeople()}><i className="fa-solid fa-eraser me-2"></i>Clear all find and sort (F5)</button>

            {
                showAdd && (
                    <div className='mt-2' style={{ border: '1px solid black', padding: "10px", width: "fit-content" }}>
                        <form className='d-flex flex-wrap gap-2' onSubmit={(e) => handleAddPerson(e)}>
                            <input value={vehicle_no} onChange={(e) => setVehicleNo(e.target.value)} autoComplete='off' list="allvehiclenos" placeholder='Vehicle No' />
                            <datalist id="allvehiclenos">
                                {allVehicleNo.map((val, index) => {
                                    return (
                                        <option key={index} value={val.vehicle_no} />
                                    )
                                })}
                            </datalist>
                            <input value={username} onChange={(e) => { setUserName(e.target.value) }} onBlur={(e) => { setUsertypeWan(e.target.value) }} autoComplete='off' list="allvendordrivernamewan" placeholder='User Name' />
                            <datalist id="allvendordrivernamewan">
                                <option value='-- Vendor --'></option>
                                {allVendorsName.map((val, index) => {
                                    return (
                                        <option key={index} value={`${val.name}`} />
                                    )
                                })}

                                <option value=' '></option>
                                <option value='-- Driver --'></option>

                                {allDriversName.map((val, index) => {
                                    return (
                                        <option key={index} value={`${val.drivername}`} />
                                    )
                                })}
                            </datalist>
                            <input type="password" placeholder='Password' required value={password} className='' onChange={(e) => setPassword(e.target.value)} />
                            <input type="password" placeholder='Confirm Password' required value={confirmPassword} className='' onChange={(e) => setConfirmPassword(e.target.value)} />
                            <select ref={input1} className='' value={usertype} onChange={(e) => setUserType(e.target.value)}>
                                <option value="" selected disabled>User Type</option>
                                <option value="vendor">Vendor</option>
                                <option value="driver">Driver</option>
                            </select>
                            <input ref={input2} type="number" placeholder='Whatsapp number' value={wan} className='' onChange={(e) => setWan(e.target.value)} />
                            <button className='btn btn-sm btn-primary' type='submit'>{editIndex !== null ? 'Update' : 'Add'}</button>
                        </form>
                    </div>
                )
            }

            {
                showFindSearch && (
                    <form onSubmit={(e) => filterSearch(e)} className='mt-2' style={{ border: '1px solid black', padding: "10px", width: "fit-content" }}>
                        <select onChange={(e) => { setSearchColType(e.target.value); }} className='me-2' style={{ height: "30px", width: "150px" }}>
                            {columnOrder.map((column, index) => (
                                column[1] == 'checkbox' ? <></> :
                                    <option value={column[0]}>{column[0]}</option>
                            ))}
                        </select>
                        <select onChange={(e) => { setSearchSign(e.target.value) }} required className='me-2' style={{ height: "30px", width: "150px" }}>
                            {signFromColType == true ?
                                <>
                                    <option value="="> = (Equal)</option>
                                    <option value="!="> != (Not Equal)</option>
                                    <option value="<="> {'<='} (Less Than or Equal)</option>
                                    <option value=">=">{'>='} (Greater Than or Equal)</option>
                                    <option value="<"> {'<'} (Less Than)</option>
                                    <option value=">"> {'>'} (Greater Than)</option>
                                </>
                                :
                                <>
                                    <option value="contains">Contains</option>
                                    <option value="doesnotcontains">Does not contains</option>
                                    <option value="beginwith">Begin with</option>
                                </>
                            }
                        </select>

                        <input type="text" onChange={(e) => { setSearchValue(e.target.value) }} required className='me-2' style={{ height: "30px", width: "150px" }} />
                        <button type='submit' className='btn btn-sm btn-success'>Search</button>
                    </form>
                )
            }
            <div className='mt-2' style={{ width: '100%', maxHeight: "70vh", overflow: 'auto', padding: "5px 150px 10px 10px", border: "1px solid black" }}>
                <table className='' style={{ width: '100%', tableLayout: 'fixed' }}>
                    <thead>
                        <tr>
                            {columnOrder.map((column, index) => (
                                <th
                                    key={column}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, index)}
                                    onDrop={(e) => handleDrop(e, index)}
                                    onDragOver={handleDragOver}
                                    onClick={(e) => { handleSort(e, column[0]) }}
                                    data-bs-toggle="tooltip" data-bs-placement="top" title={setterscolumns[column[0]]}
                                    style={{ padding: "0 10px", width: "150px", textAlign: "center", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                >
                                    <i className="fa-solid fa-sort me-2"></i>{setterscolumns[column[0]]}
                                </th>
                            ))}
                            <th style={{ paddingLeft: "38px" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan={columnOrder.length + 1}>
                                    <Loading />
                                </td>
                            </tr>
                        ) : (
                            people.map((person, index) => (
                                <tr key={person.id}>
                                    {columnOrder.map((column) => (
                                        <td
                                            key={`${person.id}-${column}`}
                                            style={{ textAlign: "center", maxWidth: '150px', border: "1px solid black", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                        >
                                            {editIndex === index ? (
                                                column[1] === 'select' ? (
                                                    settersselections[column[0]]
                                                ) : column[1] === 'text' || column[1] === 'date' ? (
                                                    <input
                                                        type={column[1]}
                                                        value={settersvariable[column[0]]}
                                                        onChange={(e) => setters[column[0]](e.target.value)}
                                                        style={{ width: '100%' }}
                                                    />
                                                ) : (<></>)
                                            ) : (
                                                column[0] != 'wan' ? (
                                                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={String(person[column[0]])}>{String(person[column[0]])}</span>
                                                ) : (
                                                    (person['wanverified'] == true ? <>{String(person[column[0]])} <i title="Verified" className="text-success ms-2 fa-solid fa-square-check"></i></> : <>{String(person[column[0]])}<i title='Unverified' className="text-warning ms-2 fa-solid fa-triangle-exclamation"></i></>)
                                                )
                                            )}
                                        </td>
                                    ))}
                                    <td>
                                        {editIndex === index ? (
                                            <div style={{ display: "flex" }}>
                                                <button className='btn btn-sm btn-secondary ms-2' onClick={handleCancelBtn}>Cancel</button>
                                                <button className='btn btn-sm btn-success  ms-2' onClick={handleAddPerson}>Save</button>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex" }}>
                                                <button className='btn btn-sm btn-warning ms-2' disabled={disableUpdate} onClick={() => { setDisableAdd(true); handleEditPerson(index) }}>Edit</button>
                                                <button className='btn btn-sm btn-danger ms-2' onClick={() => handleDeletePerson(index)} style={{ marginRight: "50px" }}>Delete</button>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default ThirdPartyAccess